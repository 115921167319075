import { Flex, Heading, Image } from "@tempo/core";
import React from "react";
import buildConfig from "build-config.json";

const NotFoundPage: React.FC = () => (
  <Flex
    flexDir="column"
    alignItems="center"
    justifyContent="center"
    minH="100vh"
    minW="100vw"
    bg="gray.50"
  >
    <Image
      src={`${buildConfig.host_assets_ssl}/images/deezer-logo.svg`}
      alt="deezer-logo"
      height="175px"
      mb="8"
    />
    <Heading as="h1" alignItems="center">
      Sorry, this page isn&apos;t available
    </Heading>
  </Flex>
);

export default NotFoundPage;
